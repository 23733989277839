// npm install html5-qrcode

import React, {useState, useEffect, useRef} from "react"
import PropTypes from "prop-types"

import { Html5Qrcode } from 'html5-qrcode';


import FileBrowser from "../filebrowser"
import imgclose from "../../../images/buttons/close.png"

import * as styles from "./style.module.css"


const QRScanner = ({scanning, title, elementid, fileupload, resultcallback}) => {
	const [loading, setLoading] = useState(false);

	//const finalelementid = typeof elementid !== "undefined" ? elementid : "qrscanner"+(new Date()).getTime();
	// Date Time changes every re-render?
	const finalelementid = typeof elementid !== "undefined" ? elementid : "qrscannercameraholder";
	const qrelem = useRef(null);

	var html5qrcodeobj = null;

	useEffect(()=>{
		if (html5qrcodeobj === null ) html5qrcodeobj = new Html5Qrcode(finalelementid);

		if (scanning === false) {
			try {
				html5qrcodeobj.stop();
			} catch(e) {

			}
		} else if (typeof window !== "undefined") {
			const config = { fps: 10 };
			const portaitmode = window.innerWidth<window.innerHeight;
			const qrboxpx = Math.floor(portaitmode?window.innerWidth*0.9:window.innerHeight*0.7);

			qrelem.current.style.width=qrboxpx+"px";
			qrelem.current.style.display="inline-block";
			try {
				html5qrcodeobj.start({
						facingMode: portaitmode?"environment":"user" // environment:Rear, user:Front (webcam)
					},
					config,
					function(scanValue, scanResult) {
						try {
							html5qrcodeobj.stop();
						} catch(e) {

						}
						resultcallback(scanValue, true);
					}); // start
			} catch(starterror) {
				resultcallback("Unable to start", false);
			}

		}
	}, [scanning])

	const cancelScan = function(e) {
		if (e) {
			e.preventDefault();
		}
		try {
			if (html5qrcodeobj !== null) {
				html5qrcodeobj.stop();
			}
		} catch(e) {

		}
		resultcallback("Cancelled", false);
	};

	const fileSelectedHandler = (event) => {
		if (event.target.files.length > 0) {
			setLoading(true);
			try {
				html5qrcodeobj.stop();
			} catch(e) {

			}

			setTimeout(function(){
				// Wait for stop,
				html5qrcodeobj.scanFile(event.target.files[0], false).then(decodedText => {
					// success, use decodedText
					setLoading(false);
					resultcallback(decodedText, true);
				}).catch(err => {
					setLoading(false);
					resultcallback("Unable to process/invalid image", false);
				});
			}, 1000);

		}
	};

	useEffect(()=>{
		if (typeof window === 'undefined') return;
		if (html5qrcodeobj === null ) html5qrcodeobj = new Html5Qrcode(finalelementid);

		return () => {
			if (null !== html5qrcodeobj) {
				try {
					html5qrcodeobj.stop();
				} catch(e) {

				}
			}
		}
	}, [elementid])

	return (<div style={scanning === false?{display:"none"}:{}} className={styles.holder}>
			<div className={styles.shadow}>&nbsp;</div>
			<div className={styles.modal}>
				{title.length > 0 && <div className={styles.modaltitle}>{title}</div>}
				<div ref={qrelem} id={finalelementid} />
				<div>
					{fileupload && <FileBrowser
						disabled={loading}
						handleFile={fileSelectedHandler}
						textlabel={""}
						className={styles.action+" iconbutton"}
					/>}
					<button title="Cancel" className={styles.action} disabled={loading} onClick={cancelScan}>
						<img src={imgclose} alt="x" />
					</button>

				</div>
				<div className={styles.notes}>
					<div><strong>Having trouble scanning?</strong></div>
					<div>
						Try the following:
						<ul>
							<li>Move QR or Camera away then closer</li>
							<li>Place QR under better light</li>
						</ul>
					</div>
				</div>
			</div>
		</div>)
}


QRScanner.propTypes = {
	scanning: PropTypes.bool,
	fileupload: PropTypes.bool,
	title: PropTypes.string
};

QRScanner.defaultProps = {
	scanning: false,
	fileupload: true,
	title: "Scan QR Code"
};


export default QRScanner