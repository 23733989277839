
import React, {useState, useEffect} from "react"
import {navigate} from "gatsby"

import Layout from "../components/layout"
import WebappsMenupageComponent from "../components/webapps/components/menupage";

import authmenulist from "../content/authmenulist.json";

import * as styles from "../styles/pages/home.module.css"

import imgmenuclose from "../images/menu-close.png"
import imgscanqr from "../images/buttons/scan_qr.png"

import QRScanner from "../components/controls/qrscanner";


const webappsAPI = require("../../lib/requestWebapps");

// Placeholder to prevent errors
//const QRScanner = () => {return <></>}

const ScanQRPage = ({location}) => {
	const [token, setToken] = useState("");
	const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState("Loading...");
	const [messagedetails, setMessagedetails] = useState("");
	const [userinfo, setUserinfo] = useState({});

	const [intentid, setIntentid] = useState("");
	const [intentparam, setIntentparam] = useState("");

	const [scanlist, setScanlist] = useState([]);
	const [scanpath, setScanpath] = useState("");
	const [scanagain, setScanagain] = useState(false);
	const [scanmode, setScanmode] = useState(false);


	useEffect(()=> {
		if (token.length < 1) {
			return;
		}
		const querylist = ["search", "hash"];
		var queryparam = {};
		var querystring = "";
		var querystrdivider = "";
		var tmpidx = 0;

		while (tmpidx < querylist.length) {
			if (location.hasOwnProperty(querylist[tmpidx])) {
				if (location[querylist[tmpidx]]) {
					querystring = querystring + querystrdivider + location[querylist[tmpidx]].substring(1);
					querystrdivider = "&";
				}
			}
			tmpidx++;
		}
		if (querystring.length > 0) {
			const tmpparamlist = Array.from(new URLSearchParams(querystring));
			tmpidx = 0;
			while (tmpidx < tmpparamlist.length) {
				if (tmpparamlist[tmpidx][1] !== null) {
					queryparam[tmpparamlist[tmpidx][0]] = tmpparamlist[tmpidx][1];
				}
				tmpidx++;
			}
		}
		querystring = "/";
		if (queryparam.hasOwnProperty("source")) {
			if (queryparam.source.length > 0) {
				querystring = queryparam.source;
			}
		}

		setScanmode(true);
		setScanpath(querystring);

		updateMessage("");
		setLoading(false);

	}, [location, token]);

	const updateMessage = function(mainmsg, detailsmarkup = "")
	{
		setMessage(mainmsg);
		setMessagedetails(detailsmarkup);
	}

	const handleIntent = function(data, detailsmarkup = "")
	{
		updateMessage("Processing...", detailsmarkup);
		setLoading(true);
		var param = "";

		setIntentid(data.z);

		if (data.hasOwnProperty("d")) {
			if (data.d) {
				const docid = parseInt(data.d,10);
				if (!isNaN(docid)) {
					const linkprefix = (((new Date()).getTime()&0xff).toString(16)+"0").substring(0,2);
					param = "?docid="+webappsAPI.encodePayload(linkprefix+data.d, "b");
				}
			}
		}
		setIntentparam(param);

		updateMessage("");
		setLoading(false);

		return true;
	}

	const handleScanData = function(data)
	{
		if (data.hasOwnProperty("t") && data.hasOwnProperty("n")) {
			// multiple QR
			const packid = parseInt(data.n, 10);
			const numqr = parseInt(data.t, 10);
			if (isNaN(numqr) || isNaN(packid)) {
				updateMessage("Invalid QR code");
				return false;
			}
			var idlist = scanlist;
			var usrmg = "";
			var newmessagedetails = messagedetails;
			if (scanlist.includes(packid)) {
				usrmg = "Existing QR: ";
			} else {
				idlist.push(packid);
				if (data.hasOwnProperty("l")) {
					newmessagedetails = newmessagedetails+"\n"+packid+": "+data.l;
				} else {
					newmessagedetails = newmessagedetails+"\n"+packid;
				}
				setScanlist(idlist);
				if (idlist.length === numqr) {
					setScanagain(false);
					return handleIntent(data, newmessagedetails);
				}
			}
			setScanagain(true);
			updateMessage(usrmg+idlist.length+" of "+numqr+" Scanned", newmessagedetails);
			return true;
		} else {
			return handleIntent(data);
		}
	}

	const scanHandler = function(data, success)
	{
		setScanmode(false);
		if (success) {
			const outdata = webappsAPI.decodePayload(data+"");
			if (data+"" === outdata) {
				updateMessage("Invalid/Unsupported QR");
			} else {
				handleScanData(JSON.parse(outdata));
			}
		} else if (scanpath.length > 0) {
			setLoading(true);
			updateMessage("Loading...");
			navigate(scanpath);
			setLoading(false);
		}
	}

	const onMessageDismiss = function(e)
	{
		if (e) {
			e.preventDefault();
		}
		updateMessage("");
		navigate(scanpath);
	}

	const onScanNext = function(e)
	{
		if (e) {
			e.preventDefault();
		}
		setScanmode(true);
	}

	const menusrc = userinfo.hasOwnProperty("accessid")?(authmenulist["access"+userinfo.accessid]?authmenulist["access"+userinfo.accessid]:[]):[];
	var curmenu = intentid.length > 0 ? []: menusrc;

	// Filter menu based on intent
	var lastnav = "";
	var totalidx = 0;
	var intentfound = 0;
	var primaryidx = 0;
	var secondaryidx = 0;
	var tmpobj = {};
	while (primaryidx < menusrc.length) {
		if (menusrc[primaryidx].hasOwnProperty("subitems")) {
			secondaryidx = 0;
			while (secondaryidx < menusrc[primaryidx].subitems.length) {
				if (intentid.length > 0) {
					if (menusrc[primaryidx].subitems[secondaryidx].hasOwnProperty("intentlist")) {
						if (menusrc[primaryidx].subitems[secondaryidx].intentlist.includes(intentid)) {
							tmpobj = JSON.parse(JSON.stringify(menusrc[primaryidx].subitems[secondaryidx]));
							tmpobj.url = tmpobj.url + intentparam;
							curmenu.push(tmpobj);
							lastnav = tmpobj.url;
							intentfound++;
						}
					}
				}

				totalidx++;
				secondaryidx++;
			}
		} else {
			if (intentid.length > 0) {
				if (menusrc[primaryidx].hasOwnProperty("intentlist")) {
					if (menusrc[primaryidx].intentlist.includes(intentid)) {
						tmpobj = JSON.parse(JSON.stringify(menusrc[primaryidx]));
						tmpobj.url = tmpobj.url + intentparam;
						curmenu.push(tmpobj);
						lastnav = tmpobj.url;
						intentfound++;
					}
				}
			}
			totalidx++;
		}
		primaryidx++;
	}

	if (intentfound === 1) {
		navigate(lastnav+intentparam);
		return <></>
	}

	return <Layout fullPath={location.pathname}
			newUserHandler={(newtoken, newuserinfo)=>{setToken(newtoken); setUserinfo(newuserinfo)}}
			privatePage={true} usegatedcontent={true}>
			{totalidx > 0 ? <>
				{scanmode?<>
					<QRScanner scanning={scanmode} resultcallback={scanHandler} />
				</>:<>
					{message.length > 0?<>
						<br/><br/><br/><br/>
						<h1 className={styles.homewelcome+" font-size-medium text-centered"}>{message}</h1>
						{messagedetails.length > 0 && <div className="text-centered" dangerouslySetInnerHTML={{ __html: (messagedetails+"\n\n\n\n").replace(/\n/g,"<br/>") }}/>}
						{loading=== false && <div className="text-centered">
							{scanagain?<>
								<button title={"Next QR"} className={"textbutton textbutton-large"} onClick={onScanNext}>
									<img src={imgscanqr} alt={"Next QR"} />&nbsp;Next&nbsp;QR
								</button>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<button title={"Cancel"} className={"textbutton textbutton-large"} onClick={onMessageDismiss}>
									<img src={imgmenuclose} alt={"Next QR"} />&nbsp;Cancel
								</button>
							</>:<>
								<button title={"OK"} className={"iconbutton iconbutton-large"} onClick={onMessageDismiss}>
									<img src={imgmenuclose} alt={"OK"} />
								</button>
							</>}
						</div>}
						<br/><br/><br/><br/>
					</>:<>
						{(true || intentid.length > 0) &&
							<WebappsMenupageComponent
									title={"Hi "+userinfo.name}
									menusrc={curmenu}
								/>

						}
					</>}
				</>}
			</>
			:<p class="underconstruction font-size-large">
				{token.length > 0?"No items found":"Loading..."}
			</p>
			}

		</Layout>

}


export default ScanQRPage;
